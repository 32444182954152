$(document).ready(function () {
    $('body, html').scrollTop(0);
    var cM, pM, v;
    $(document).on("click", ".stylish-select-left", function () {
        $("ul.ui-autocomplete").hide();
    });

    $(document).on("keydown", "#owl-carousel-tile-filter .owl-item h2 a", function (e) {
        if (e.keyCode == "9") {
            $("#owl-carousel-tile-filter .owl-item h2 a").css('color', '#4f3996');
        }
    });

    $('.container .col-r.last .tiles .padding:eq(0)').css('padding-top', '0px');

    $('.responsive-nav ul > li:nth-child(2) .sub-menu').remove();
    $('.responsive-nav ul > li:nth-child(2) .respSubNavArrow').remove();

    /* window scroll script start */

    $(window).scroll(function () {
        topHeaderHeight = $("#top-header").height();

        if ($(window).scrollTop() > 78) {
            if ($("#nav-wrapper ul.nav-main-link > li > .sub-menu").is(":visible")) {
            } else {
                $("#top-header").addClass("sticky-header");
                return false;
            }
        } else {
            if ($(window).width() > 640) {
                $("#top-header").removeClass("sticky-header");
                return false;
            }
        }
    });
    /* window scroll script end */

    /* search and language  script start */

    $(".global-search").click(function () {
        $('#search-wrapper').hasClass('openTPan') ? searPanClose() : searPanOpen(); RegionPanClose();

        if ($(window).width() < 767) {
            if ($('#search-wrapper').hasClass('openTPan')) {
                $("#UpdateableContent").click(searPanClose);
                $(".c-srb__container.js-global").click(searPanClose);
                $(".c-srb__container.js-people").click(searPanClose);
                $(".c-srb__container.js-publications").click(searPanClose);
                $(".c-srb__container.js-news").click(searPanClose);
                $(".c-results__container.c-results__tabs.js-list-container.js-results-tabs").click(searPanClose);
                $(".container.white-container.detail-page.clr").click(searPanClose);
                $(".col-l").click(searPanClose);
                $(".layout-mobile").click(searPanClose);
            }
        }
    });

    $("#search-wrapper .col-3 a.icon-close").click(searPanClose);

    $("#language-select-toggle").click(function () {
        $('#language-select-container').hasClass('openTRegPan') ? RegionPanClose() : RegionPanOpen(); searPanClose();

        if ($(window).width() < 767) {
            if ($('#language-select-container').hasClass('openTRegPan')) {
                $("#UpdateableContent").click(searPanClose);
                $(".c-srb__container.js-global").click(searPanClose);
                $(".c-srb__container.js-people").click(searPanClose);
                $(".c-srb__container.js-publications").click(searPanClose);
                $(".c-srb__container.js-news").click(searPanClose);
                $(".container.white-container.detail-page.clr").click(searPanClose);
                $(".col-l").click(searPanClose);
                $(".c-results__container.c-results__tabs.js-list-container.js-results-tabs").click(searPanClose);
                $(".layout-mobile").click(searPanClose);
            }
        }
    });

    $("#language-select-close").click(RegionPanClose);

    /*Search panel function*/
    function searPanOpen() {
        $('#search-wrapper').addClass('openTPan');
        $('#search-wrapper').stop(true).slideDown(function () { $("#txtSiteSearch").focus() });
        $('.btn-global-search').addClass('btn-global-search-close');
    }

    function searPanClose() {
        $('#search-wrapper').removeClass('openTPan');
        $('#search-wrapper').stop(true).slideUp(function () { $(".global-search input").focus() });
        $('.btn-global-search').removeClass('btn-global-search-close');
        $('body').removeClass('stopScrol');
    }

    /*Region panel function*/
    function RegionPanOpen() {
        $('#language-select-container').addClass('openTRegPan');
        $('#language-select-container').stop(true).slideDown();
        $('#language-select-toggle').addClass('language-btn-close');
    }

    function RegionPanClose() {
        $('#language-select-container').removeClass('openTRegPan');
        $('#language-select-container').stop(true).slideUp();
        $('#language-select-toggle').removeClass('language-btn-close');
    }

    /* search and language script end */

    /* accordian start */    
    function collapseExpandClickHandler(element) {
        if (!($(element).parent().hasClass("noicon"))) {

            if ($(element).hasClass("expand")) {
                $(element).removeClass("expand");
                $(element).parent().find(".collapse-expand-cont").slideUp();
            } else {
                $(element).parent().find(".collapse-expand-cont").slideDown();
                $(element).addClass("expand");
            }
        }
    }

    $(".collapse-expand-a .block h2").click(function () {
        collapseExpandClickHandler(this);
    });
    $(".collapse-expand-a .block button").click(function () {
        collapseExpandClickHandler(this);
    });

    $(".collapse-expand-a .block h2").keypress(function (e) {
        //keycode 32 = spacebar, 13 = enter
        var keycode = e.keyCode ? e.keyCode : e.which;
        if (keycode == 32 || keycode == 13) {
            collapseExpandClickHandler(this);
        }
    });

    if (jQuery('.event-series').length > 0) {
        $('.block').each(function () {
            if (jQuery(this).find('.collapse-expand-cont').children().length == 0) {
                jQuery(this).hide();
            } else { jQuery(this).show(); }
        });
    }

    /* accordian end */

    /* search result accordian start */
    $(".layout-mobile .related-source h1").click(function () {
        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().find(".filterArea").addClass('hide');
        } else {
            $(this).parent().find(".filterArea").removeClass('hide');
            $(this).addClass("expand");
        }
    });

    /* search result accordian end */

    /* location accordian start */
    var locationAccordionToggle = function (ele) {
        var data_Title = $(ele).attr('data-accordion-panel-selector');
        if ($(ele).hasClass("mobile-location-landing-list__region-heading--expand")) {
            // accordion is open, close it
            $(ele).removeClass("mobile-location-landing-list__region-heading--expand");
            $(ele).attr('aria-expanded', 'false');
            $('.' + data_Title).slideUp();
            $('.' + data_Title).attr('hidden');
        }
        else {
            // accordion is closed, open it
            $('.' + data_Title).slideDown();
            $('.' + data_Title).removeAttr('hidden');
            $(ele).addClass("mobile-location-landing-list__region-heading--expand");
            $(ele).attr('aria-expanded', 'true');
        }
    }
    $('.mobile-location-landing-list__region-heading').click(function () {
        locationAccordionToggle(this);
    });
    $('.mobile-location-landing-list__region-heading').keypress(function (e) {
        //keycode 32 = spacebar, 13 = enter
        var keycode = e.keyCode ? e.keyCode : e.which;
        if (keycode == 32 || keycode == 13) {
            locationAccordionToggle(this);
        }
    })
    /* location accordian end */

    /* Landing page accordian start */
    $('.top-level-cont .top-level-three-col .cols .block h2.exp-coll-arrow').click(function () {
        if ($(window).width() < 500) {
            var data_Title = $(this).attr('data-title');
            if ($(this).hasClass("expand")) {
                $(this).removeClass("expand");
                $('.' + data_Title).hide();
            } else {
                $(".layout-mobile .related-source h1").removeClass("expand");
                $(".inner-wrapper clr").hide();
                $('.' + data_Title).show();
                $(this).addClass("expand");
            }
        }
    });
    /* Landing page accordian end */

    /* hidden para */
    $(".top-level-cont .top-level-two-col .col-l .show-more-link1 a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hidden-cont").slideUp();
            $(this).text("Show More");
        } else {
            $(this).parent().parent().find(".hidden-cont").slideDown();
            $(this).addClass("expand");
            $(this).text("Show Less");
        }
    });
    $(".collapse-box .show-more-link1 a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hidden-cont").slideUp();
        } else {
            $(this).parent().parent().find(".hidden-cont").slideDown();
            $(this).addClass("expand");
        }

        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });

    /* hidden para end */

    /* hidden para for accordian */
    $(".collapse-expand-cont a.more").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hidden-cont").slideUp();
        } else {
            $(this).parent().parent().find(".hidden-cont").slideDown();
            $(this).addClass("expand");
        }

        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });


    /* end */

    /* hidden contact person */
    $(".contact-list .show-more-link a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hide").slideUp();
        } else {
            $(this).parent().parent().find(".hide").slideDown();
            $(this).addClass("expand");
            var list = $(".contact-list .hide a");
            if (!!list && !!list.first()) {
                var first = list.first()
                first.focus();
            }
        }

        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });
    /* hidden contact person */
    $(".js--show-more-toggle .show-more-link a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hide").slideUp();
        } else {
            $(this).parent().parent().find(".hide").slideDown();
            $(this).addClass("expand");
        }

        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });
    /* hidden subsection List */
    $(".subsection-list .show-more-link a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().parent().find(".hide").slideUp();
        } else {
            $(this).parent().parent().find(".hide").slideDown();
            $(this).addClass("expand");
        }
        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });
    /* hidden subsection List */

    /* hidden our people form */
    $(".moreOption a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().next(".hiddenForm").slideUp();
            $(this).text("more options");
        } else {
            $(this).parent().next(".hiddenForm").slideDown();
            $(this).addClass("expand");
            $(this).text("less options");
        }
    });
    /* hidden our people form */

    /* hidden our publication form */
    $(".fewerOption a").click(function (event) {
        event.preventDefault()
        event.stopPropagation()
        document.activeElement.blur()

        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().next(".hiddenFewerForm").slideUp();
        } else {
            $(this).parent().next(".hiddenFewerForm").slideDown();
            $(this).addClass("expand");
        }

        var showText = $(this).attr("data-show");
        var hideText = $(this).attr("data-hide");
        if ($(this).text() == showText) {
            $(this).text(hideText);
        } else {
            $(this).text(showText);
        }
    });
    /* hidden our publication form */

    /* special topics team list start */
    $('.special-topic.teamlist .show-more-link a').click(function (e) {
        var element = $(e.target);
        var container = $(element).parents('.special-topic.teamlist')[0];
        var isExpanded = $(element).hasClass('expand');
        var text = '';
        if (isExpanded) {
            text = $(element).attr('data-show');
            $(container).find('.hide').slideUp();
        }
        else {
            text = $(element).attr('data-hide');
            $(container).find('.hide').slideDown();
        }
        $(element).toggleClass('expand');
        $(element).text(text);
    });
    /* special topics team list end */

    $(".responsive-filter h2").click(function () {
        if ($(this).hasClass("expand")) {
            $(this).removeClass("expand");
            $(this).parent().find(".filter-category").slideUp();
        } else {
            $(this).parent().find(".filter-category").slideDown();
            $(this).addClass("expand");
        }
    });

    $(".responsive-filter .filter-category ul li a").click(function () {
        var fetchText = $(this).text();
        $(".responsive-filter h2").html(fetchText);
        $(".responsive-filter h2").removeClass("expand");
        $(this).parents().find(".filter-category").slideUp();
    });

    /* For Event Search */
    $('.pubSearchEvent').click(function () {
        $('.pub-search-area').show();
        $(".tile-wrapper").hide();
        $(".container .col-r .tiles").hide();
    });

    /*for pub search */
    $('.pubSearchPub').click(function () {
        $(".tile-wrapper").hide();
        $(".container .col-r .tiles").hide();
    });

    $('.pubReset a').click(function () {
        $('.pub-search-area').hide();
        $(".pubSearch").attr("value", "Search");
    });

    $("#owl-carousel-tile-filter h2 a").click(function () {
        $("#owl-carousel-tile-filter h2 a").removeClass("selected");
        if ($(this).hasClass("selected")) {
            $(this).removeClass("selected");
        } else {
            $(this).addClass("selected");
        }
    });

    /* for press release */
    $(".search-media-form .enterBtn input.search-rtn").click(function () {
        $(".media-result-wrapper").show();
        $('html, body').animate({
            'scrollTop': $('.media-result-wrapper').offset().top - 250
        });
        $(".tile-wrapper").hide();
    });

    /* for people search */
    $(".peopSearSect .enterBtn input.search-rtn").click(function () {
        $(".people-search-result-wrapper").show();
        $(".tile-wrapper").hide();
        $('html, body').animate({
            'scrollTop': $('.people-search-result-wrapper').offset().top - 250
        });
    });

    /* for go to top*/
    $(".goTop img").click(function () {
        $("html, body").animate({
            scrollTop: 0
        })
    });

    $(window).scroll(function () {
        $(this).scrollTop() > 300 ? $(".goTop").fadeIn() : $(".goTop").fadeOut()
    });

    $(".alphaSearch a").click(function () {
        $(".people-search-result-wrapper").show();
        $(".peopSearSect .enterBtn input.clr-search").css("display", "block");
        $(".tile-wrapper").hide();

        $(".media-result-wrapper").show();
        $(".search-media-form .enterBtn input.clr-search").css("display", "block");
        $('html, body').animate({
            'scrollTop': $('.people-search-result-wrapper').offset().top - 250
        });
    });

    $("input[type='reset'], .pubReset a, .search-again").click(function () {
        if ($(window).width() > 500) {
            $('html, body').animate({
                'scrollTop': $('.white-container').offset().top - 20
            });
        }
        else {
            $('html, body').animate({
                'scrollTop': $('.white-container').offset().top - 100
            });
        }
    });

    $("input[type='button'].register").click(function () {
        $(".frm-register-wrapper").hide();
        $(".thankyou-wrapper").show();
    });

    $("input[type='reset']").click(function () {
        $(".peopSearSect input[type='text']").removeAttr("filter-indexid");
        $(".peopSearSect input[type='text']").removeAttr("filter-indexname")
    });

    /* event details ls move */
    var eventDet = $('.location-det').html();
    $('.location-det-ls').addClass("location-det");
    $('.location-det-ls').html(eventDet);

    /******************************** print section start ************************************/
    $(".print-head .container .left span").mouseover(function () {
        $(".filter-option").slideDown();
    });
    $(".print-head .container .left").mouseleave(function () {
        $(".filter-option").slideUp();
    });

    if ($(".print-extent-p").length == 2) {
        $("input[value='print-extent-p']").show();
    }
    $('.filter-option .checkbox input[type="checkbox"]').click(function () {
        var checkboxAttr = $(this).attr("value");
        if ($(this).is(":checked")) {
            $("." + checkboxAttr).show();
        } else {
            if ($("." + checkboxAttr).length > 1) {
                $("." + checkboxAttr).not(":first").hide();
            } else {
                $("." + checkboxAttr).hide();
            }
        }
    });

    function optionalCheck() {
        $(".filter-option .checkbox input").each(function () {
            var checkboxAttr = $(this).attr("value");
            if ($(this).is(":checked")) {
                $("." + checkboxAttr).show();
            } else {
                $("." + checkboxAttr).hide();
            }
        });
    }

    optionalCheck();
    var printClass = '';
    $(".print-wrapper .collapse-expand-a .block").each(function () {
        fetchClass = $(this).attr('class');
        var updatedClassname = fetchClass.replace('block ', '')
        $(".filter-option .checkbox input").each(function () {
            var checkboxAttr = $(this).attr("value");
            if (checkboxAttr == updatedClassname) {
                $(this).parent().parent().css('display', 'block');
            }
        });
    });

    $('.other-sec li').show().find('input').removeAttr('disabled');
    $('.other-sec li input[value="print-location-det"], .other-sec li input[value="person-det-print"], .other-sec li input[value="people-intro"]').attr('disabled', true).prop('checked', true);
    $('input[value="print-admission"], .other-sec li input[value="person-det-print"], .other-sec li input[value="people-intro"]').attr('disabled', true).prop('checked', true);

    /******************************** print section end ************************************/

    /* skip section start */
    $(document).keydown(function (n) {
        if (n.keyCode == 9) {
            $("#scrollableutility").css("left", "35%");
            $("#scrollableutility").css("overflow", "visible");
            $("#scrollableutility").css("height", "auto");
            $("#scrollableutility").css("z-index", "1");
        }
    });

    $("#scrollableutility li a").keydown(function (h) {
        if (h.keyCode == 13) {
            h.preventDefault();
            var target = $(this).attr('href');
            $(target).find(' a,input[type=text],select,textarea').filter(':visible:first').focus();
        }
    });

    $(".skip-nav").click(function () {
        $('html, body').stop().animate({ scrollTop: 0 }, 'slow', function () {
            $('#nav-wrapper').find(' a,input[type=text],select,textarea').filter(':visible:first').focus();
        });
    });
    /* skip section end */

    $(".responsive-nav .padding10 ul.nav-main-link > li.menu-item-people div.sub-menu").remove();
    /* Global script*/
    $("a.newspdf").attr('target', '_blank');
    var txtResultFound = ($(".filter-search-head .left").text());

    $(".people-intro p:empty").remove();
    $(".print-wrapper p:empty").remove();
    $(".location-det-ls:empty").remove();
    $(".top-level-cont .top-level-three-col .cols .block .block-inner p:empty").remove();

    $(".print-wrapper .datetime-zone").parent().css("margin-bottom", "0px");

    /* $(".people-intro p").first().addClass("social-media-link-ls"); */
    $(".purple-para :not(.heading-brief) p, .purple-para > p").first().addClass("heading-brief");
    //$(".purple-para > p").first().addClass("heading-brief");
    /*$(".address-block p").removeClass("heading-brief");*/
    /*$(".collapse-expand-cont p").removeClass("heading-brief");*/
    /* Global script*/

    /* carousel select overlap dots */
    $(document).on('click', '.stylish-select-left', function () {
        if ($('#careerOwlSlider.owl-theme .owl-controls').css('z-index') != -1) {
            $('#careerOwlSlider.owl-theme .owl-controls').css('z-index', '-1');
        }
        else {
            $('#careerOwlSlider.owl-theme .owl-controls').css('z-index', '1');
        }
    });
    $(document).click(function () {
        $('#careerOwlSlider.owl-theme .owl-controls').removeAttr('style');
    });

    $(document).on('click', '#ulCareerList .listing li', function () {
        $('#careerOwlSlider.owl-theme .owl-controls').css('z-index', '1');
    });
    /* carousel select overlap dots */

    /* no script */
    $(".noscript div a.btn").click(function () {
        $(".noscript").hide();
    });
    /* end */

    /* mega menu select change start */
    //$(document).on("keydown", "#posArrowDown .stylish-select-left", function (e) {
    //    if (e.keyCode == "40") {
    //            $(this).next().show();
    //            $(this).next().find("li.selected").focus();
    //            e.preventDefault();

    //    }
    //});

    //$(document).on("keydown", "#locArrowDown .stylish-select-left", function (e) {
    //    if (e.keyCode == "40") {
    //        $(this).next().show();
    //        $(this).next().find("li.selected").focus();
    //        e.preventDefault();
    //    }
    //});
    /* mega menu select change end */

    /*Owl carowserl button*/
    $('.owl-dot').click(function () {
        $('.owl-dot').removeClass('active');
        $(this).addClass('active');
    });

    /* when loading the page with hash(#) */
    var subSite = location.pathname.substr(1, 7);
    //Check if Legacy. Code should work of Legacy and not Careers pages
    if (subSite !== "careers" && location.hash.length !== 0) {
        var hash = location.hash;
        var hashOffset = $(hash).offset();
        if (hashOffset) {
            $('html, body').animate({
                'scrollTop': hashOffset.top - 70
            });
        }
    }

    //when clicking any link with hash(#)
    $('a').click(function (event) {
        var href = this.getAttribute('href');
        if (href) {
            if (href.indexOf("#") > -1) {
                var hash = href.substr(href.indexOf("#"));
                var hashOffset = $(hash).offset();
                //Check if Legacy. Code should work of Legacy and not Careers pages
                if (subSite !== "careers" && hash !== 0 && hashOffset) {
                    $('html, body').animate({
                        'scrollTop': hashOffset.offsetTop
                    });
                }
            }
        }
    });
});
/* document ready end */

/* functions start */
function AllPrintCheck() {
    $(".filter-option .checkbox input").each(function () {
        $(this).prop('checked', true);
        var checkboxAttr = $(this).attr("value");
        $("." + checkboxAttr).show();
    });
}
function processBeforeDateClick(picker) {
    $("ul.ui-autocomplete").hide();
    $("ul.listing").hide();
}
function PdfPrintCheck(showClasses) {
    if (showClasses && showClasses != '' && showClasses.trim() != '') {
        var hideTheContent = 'print-selected-represent,print-award,print-clerk,print-education,print-event,print-Article,print-Publication,print-license,print-relatedResource';
        var classTohide = hideTheContent.split(',');
        $.each(classTohide, function (index, value) {
            /*console.log(index + ": " + value);*/
            $("." + value).hide();
        });
        var classArr = showClasses.split(',');
        $.each(classArr, function (index, value) {
            /*console.log(index + ": " + value);*/
            $("." + value).show();
        });
    }
    else {
        $(".filter-option .checkbox input").each(function () {
            $(this).attr('checked', 'checked');
            var checkboxAttr = $(this).attr("value");
            $("." + checkboxAttr).show();
        });
    }

    /* for pdf page break */

    var listOfSections = 'remove-heading,print-selected-represent,print-award,print-license,print-clerk,print-education,print-relatedResource,print-event,print-Article,print-Publication';
    var arrlistOfSections = listOfSections.split(',');

    var currentHeight = $('.' + arrlistOfSections[0]).height() - 80;

    for (var counterOfArr1 = 1; counterOfArr1 < arrlistOfSections.length;) {
        var cssDiv = '.' + arrlistOfSections[counterOfArr1];
        var itemHeight = $(cssDiv).height();
        if (itemHeight && $(cssDiv).css('display') != 'none') {
            if (arrlistOfSections[counterOfArr1] == 'print-relatedResource') {
                $('.print-relatedResource .side-section-listing').each(function () {
                    var itemHeight1 = $(this).height();
                    if (itemHeight1 && $(this).css('display') != 'none') {
                        if (currentHeight % 850 <= 700) {
                            currentHeight = currentHeight + itemHeight1;
                        }
                        else {
                            $(this).css({
                                'page-break-inside': 'avoid'
                            });
                            currentHeight = itemHeight1;
                        }
                    }
                });
            }
            else {
                if (currentHeight % 850 <= 700) {
                    currentHeight = currentHeight + itemHeight;
                }
                else {
                    $('.' + arrlistOfSections[counterOfArr1]).css({
                        'page-break-inside': 'avoid'
                    });
                    currentHeight = itemHeight;
                }
            }
        }
        counterOfArr1++;
    }
    /* end of page break */
}

/* skip section start */
/* ByPass Block:Passing the (id or class name to the name) argument and class/id to the type argument*/
//function onscrollToAttribute(name) {
//    $(name).find(' a,input[type=text],select,textarea').filter(':visible:first').focus();
//    $(window).stop().scrollTop($(name).offset().top);
//}
//$(".skip-nav").click(function () {
//    $('html, body').stop().animate({ scrollTop: 0 }, 'slow', function () { $('#nav-wrapper').find(' a,input[type=text],select,textarea').filter(':visible:first').focus(); });

//})

/* skip section end */

function onFocusDefault(obj) {
    obj.style.color = "#000";
    if ($(obj).val().toLowerCase() == $(obj).attr('defaultvalue').toLowerCase()) {
        $(obj).val('');
    }
}
function onBlurDefault(obj) {
    if ($(obj).val() == '') {
        obj.style.color = "#7f7f7f";
        $(obj).val($(obj).attr('defaultvalue'));
    }
}

function checkDefault(obj) {
    var myobj = "#" + obj;
    if ($(myobj).val().toLowerCase() == $(myobj).attr('defaultvalue').toLowerCase()) {
        $(myobj).css('color', '#7f7f7f');
    } else {
        $(myobj).css('color', '#000000');
    }
}

function checkDataDefault(obj) {
    var myobj = "#" + obj;
    if ($(myobj).val().toLowerCase() == $(myobj).attr('data-defaultvalue').toLowerCase()) {
        $(myobj).css('color', '#7f7f7f');
    } else {
        $(myobj).css('color', '#000000');
    }
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/* textbox focus end */

/* 24-08-15  7-23 */

/* Translations for Date Format */
var mlMoment = function () {
    var _self = this;

    _self.init = function () {
        if (typeof moment !== "function") {
            return;
        }

        var htmlLanguage = $("html").attr("lang");
        moment.locale(htmlLanguage);
        _self.format(".tile-cont h4"); // TILES
        _self.format(".search-result-area .block span"); // GLOBAL/PUBS SEARCH RESULT
        _self.format(".search-result-area .block p span.span-eventResult"); // EVENT SEARCH RESULT
        _self.format(".news-wrapper .article-block a span.date"); // NEWS SEARCH RESULT
        _self.format("span.datetime-zone"); // EVENT PAGE
        _self.format(".page-heading span.small-head-next"); // PUBLICATION PAGE
        _self.format(".collapse-expand-cont p a span.date"); // PUBLICATION AND NEWS LIST ACCORDION IN BIO
    }

    _self.format = function (elm) {
        $(elm).each(function (i) {
            var content = $(this).contents().first();
            var text = content.text().trim();
            var tokenEventTo = "{ml-event-to}";

            if (text.indexOf(tokenEventTo) < 0) {
                // contains only 1 date
                var $elmNew = _self.formatDate(text);
                content.replaceWith($elmNew);
            }
            else {
                // contains 2 dates (usually events)
                var textFrom = text.split(tokenEventTo)[0].trim();
                var $elmFrom = _self.formatDate(textFrom);
                var textTo = text.split(tokenEventTo)[1].trim();
                var $elmTo = _self.formatDate(textTo);

                var eventTo = $(this).data("event-to");
                var elmNew = $elmFrom.html() + "<span> " + eventTo + " </span>" + $elmTo.html();
                content.replaceWith(elmNew);
            }

            $(this).removeClass("hide");
        });
    }

    _self.formatDate = function (text) {
        var date = new Date(text);
        var $defDate = $("<span>" + text + "</span>");

        if (date == "Invalid Date") {
            return $defDate;
        }

        var dateFormat = $("#mlDateFormat").text();

        if (dateFormat == false || dateFormat.length < 1) {
            return $defDate;
        }

        if (_self.isValidDate(text) == false) {
            return $defDate;
        }

        // check override format
        var formats = ["MMM YYYY", "MMMM YYYY", "YYYY"];
        var i = 0;
        for (i = 0; i < formats.length; i++) {
            var val = formats[i];
            var format = moment(text, val, "en").format(val);
            if (format.toLowerCase() == text.toLowerCase()) {
                dateFormat = val;
                break;
            }
        }

        var translated = moment(date).format(dateFormat);
        var newText = text.replace(text, translated);
        var $elmNewText = _self.separateEnglishText(newText);
        return $elmNewText;
    }

    _self.separateEnglishText = function (text) {
        var i = 0;
        var $elm = $("<span>");
        var $baselineSpan = $("<span class='wb'>");
        var $tempSpan = $baselineSpan.clone();
        var needsToEndSpan = false;
        for (i = 0; i < text.length; i++) {
            var ch = text[i];
            if (/^[a-z0-9]+$/i.test(ch)) {
                // is alpha numeric / EN char
                if (needsToEndSpan == false) {
                    $tempSpan = $baselineSpan.clone(); // clear text
                    needsToEndSpan = true; // start getting EN text
                }

                $tempSpan.append(ch);
            }
            else { // non-EN char
                if (needsToEndSpan == true) {
                    $elm.append($tempSpan);
                    $tempSpan = $baselineSpan.clone(); // clear text
                    needsToEndSpan = false; // stop getting EN text
                }

                $elm.append(ch);
            }

            if (i == text.length - 1 && needsToEndSpan == true) {
                // last character
                $elm.append($tempSpan);
            }
        }

        return $elm;
    }

    _self.isValidDate = function (text) {
        // check override format
        var formats = ["L", "l", "LL", "ll", "MM/D/YYYY", "MM/DD/YYYY", "YYYY/MM/DD", "YYYY/MM/D", "MMMM DD, YYYY", "MMMM D, YYYY", "MMM DD, YYYY", "MMM D, YYYY", "MMMM, YYYY", "MMMM YYYY"];
        var i = 0;
        for (i = 0; i < formats.length; i++) {
            var val = formats[i];
            var format = moment(text, val, "en").format(val);
            if (format.toLowerCase() == text.toLowerCase()) {
                return true;
            }
        }

        return false;
    }
}

$(document).ready(function () {
    new mlMoment().init();
});

/** Mobile Sidebar Share Tools logic */
$('.social-media-link-ls.social-media-link-ls--mobile').on('click', function() {
    toggleSocialMediaMobile()
})

$('.social-media-link-ls__container .st-custom-button-close').on('click', function() {
    toggleSocialMediaMobile()
})

function toggleSocialMediaMobile () {
    $('.social-media-link-ls.social-media-link-ls--mobile').toggleClass('social-media-link-ls--mobile-open')
    $('.social-media-link-ls__container').toggleClass('social-media-link-ls__container--open')
}

//Legacy Sharing Tools Logic Begin

$(document).on('click', '#share-tools__icon-open', function () {
    toggleWideShareTools();
});

$(document).on('click', '#share-tools__icon-close', function () {
    toggleWideShareTools();
});

$(document).on('click', '#share-tools__open--mobile', function () {
    toggleNarrowShareTools();
});

$(document).on('click', '#share-tools__close--mobile', function () {
    toggleNarrowShareTools();
});

function toggleWideShareTools() {
    if ($('#share-tools__icon-open').hasClass('share-tools__container__icon--open')) {
        $('#share-tools__icon-open').removeClass('share-tools__container__icon--open').addClass('share-tools__container__icon--closed');
        $('#share-tools__icon-close').removeClass('share-tools__container__icon--closed').addClass('share-tools__container__icon--open');
        $('.share-tools__container--wide').removeClass('share-tools__container--wide-closed').addClass('share-tools__container--wide-open');
        $('.share-tools__container__toggle-container').removeClass('share-tools__container__toggle-container--closed').addClass('share-tools__container__toggle-container--open');
    }
    else {
        $('#share-tools__icon-open').removeClass('share-tools__container__icon--closed').addClass('share-tools__container__icon--open');
        $('#share-tools__icon-close').removeClass('share-tools__container__icon--open').addClass('share-tools__container__icon--closed');
        $('.share-tools__container--wide').removeClass('share-tools__container--wide-open').addClass('share-tools__container--wide-closed');
        $('.share-tools__container__toggle-container').removeClass('share-tools__container__toggle-container--open').addClass('share-tools__container__toggle-container--closed');
    }
}

function toggleNarrowShareTools() {
    if ($('#share-tools__open--mobile').hasClass('share-tools__container__icon--open')) {
        $('#share-tools__open--mobile').removeClass('share-tools__container__icon--open').addClass('share-tools__container__icon--closed');
        $('.share-tools__container__toggle-container').removeClass('share-tools__container__toggle-container--closed').addClass('share-tools__container__toggle-container--open');
    }
    else {
        $('#share-tools__open--mobile').removeClass('share-tools__container__icon--closed').addClass('share-tools__container__icon--open');
        $('.share-tools__container__toggle-container').removeClass('share-tools__container__toggle-container--open').addClass('share-tools__container__toggle-container--closed');
    }
}

$(window).scroll(function (e) {
    if ($(window).scrollTop() > 292 && !$('.share-tools--wide').hasClass('share-tools--sticky')) {
        $('.share-tools--wide').addClass('share-tools--sticky');
    }
    if ($(window).scrollTop() < 292 && $('.share-tools--wide').hasClass('share-tools--sticky')) {
        $('.share-tools--wide').removeClass('share-tools--sticky');
    }
});

function socialIconPositioning() {
    var bioheading = $('.person-heading-container');
    var mainheading = $('.main-heading.container');
    var inPageLanguageSelector = $('.inpage-language-selector-container');
    var headingHeight;

    if (bioheading.length > 0) {
        headingHeight = bioheading.innerHeight();
        if (inPageLanguageSelector.length > 0) {
            headingHeight = headingHeight + inPageLanguageSelector.innerHeight()
        }
    }
    else if (mainheading.length > 0) {
        headingHeight = mainheading.innerHeight();
    }

    if (headingHeight > 0) {
        var height = "-" + (headingHeight - 80) + "px";
        if ($(window).width() > 1017) {
            $('.share-tools--wide').css({ 'top': height });
        }
    }
}

/* Social Media Sidebar Share - Alignment */
$(window).on("load", function () {
    socialIconPositioning();
});

$(window).resize(function () {
    socialIconPositioning();
});

//Legacy Sharing Tools Logic End